import { select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { ConveniosCodes, StepsEnum } from '@utils/enums'
import { StepConsignadoTracking } from '@utils/tracking/tracking.types'

import { buildTrackingBody } from './models'

export default function* trackNewRelicSaga({ payload }: AnyAction) {
  const stepConsignadoValues = Object.values(StepConsignadoTracking)
  const stepNRValues = Object.values(StepsEnum)
  const simulacao = yield* select((state: ApplicationState) => state.simulacao)
  const user = yield* select((state: ApplicationState) => state.user)
  const proposta = yield* select((state: ApplicationState) => state.proposta)
  const assinatura = yield* select((state: ApplicationState) => state.assinatura)
  const navigation = yield* select((s: ApplicationState) => s.ui.navigation)

  const trackingBody = buildTrackingBody(payload, simulacao, proposta, user, assinatura, navigation)
  try {
    // TODO: remover esse artificio depois que o tracking novo for para o Siape também!
    // Se for inss E o step enviado tiver incluso nos valores da list de step novo OK
    // Se for da lista de step antigo, e diferente de inss OK
    // Garantindo que o inss não vai enviar o tracking antigo mais.
    if (
      stepConsignadoValues.includes(payload as StepConsignadoTracking) &&
      Number(simulacao?.idConvenio) === ConveniosCodes.INSS
    ) {
      yield services.postTrackNewRelic(trackingBody)
    } else if (
      stepNRValues.includes(payload as StepsEnum) &&
      Number(simulacao?.idConvenio) !== ConveniosCodes.INSS
    ) {
      yield services.postTrackNewRelic(trackingBody)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('​error', error)
  }
}
