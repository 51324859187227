/**
 * Interface referente aos trackings de APP/WEB/Consignado
 */
export interface ITrackingProps {
  /**
   * Nome do tracking no figma, ex: NOVO_INSS_FLUXO
   */
  name: string
  /**
   * Número do tracking no figma
   */
  ref_figma: '1' | '2' | '3'
  /**
   * screen/tela/step -> nome da tela
   */
  screen: StepConsignadoTracking
  /**
   * Tipo do item -> Botao, Feedback, Bottomsheet, Select, Slide, etc.
   */
  ref_type: string
  /**
   * Ação -> Toque ou Dado carregado (APP)
   */
  content_action: string
  /**
   * Ação -> click button, page_loaded, bottomsheet (WEB)
   */
  element_action: ElementAction
  /**
   * Nome que disparou ação, nome do botão ou título da mensagem carregada
   */
  action_id: string
  /**
   * Novo, Refinanciamento ou Portabilidade
   */
  product?: string
  /**
   * Url a ser redirecionada no fluxo (WEB)
   */
  redirect_url?: string
  /**
   * Campanha de onde veio (APP)
   */
  utm_campaign?: string
  /**
   * Chamar função para enviar track de consignado,
   * opcional pois nem todo tracking precisa ser passado pra o consignado
   */
  callConsignadoTrack?: (step: StepConsignadoTracking) => void
  /**
   * Params de input (WEB)
   */
  params_input?: IDataLayerParamsInput
}

export type ElementAction =
  | 'click button'
  | 'click link'
  | 'click select'
  | 'bottomsheet'
  | 'click dropdown'
  | 'submit'
  | 'click banner'
  | 'click dialog'
  | 'click video'
  | 'click scroll'
  | 'click menu'
  | 'page_loaded'

export interface IDataLayerParamsInput {
  input_name_1?: string | number | undefined | boolean
  input_value_1?: string | number | undefined | boolean
  input_name_2?: string | number | undefined | boolean
  input_value_2?: string | number | undefined | boolean
  input_name_3?: string | number | undefined | boolean
  input_value_3?: string | number | undefined | boolean
  input_name_4?: string | number | undefined | boolean
  input_value_4?: string | number | undefined | boolean
  input_name_5?: string | number | undefined | boolean
  input_value_5?: string | number | undefined | boolean
  input_name_6?: string | number | undefined | boolean
  input_value_6?: string | number | undefined | boolean
  input_name_7?: string | number | undefined | boolean
  input_value_7?: string | number | undefined | boolean
  input_name_8?: string | number | undefined | boolean
  input_value_8?: string | number | undefined | boolean
  input_name_9?: string | number | undefined | boolean
  input_value_9?: string | number | undefined | boolean
}

export enum StepConsignadoTracking {
  ANTIFRAUDE = 'Antifraude',
  ENDERECO = 'Endereco',
  ENDERECO_COMPLETO = 'Endereco completo',
  CADASTRO_BANCO = 'Cadastro banco',
  ENVIO_DOCUMENTOS = 'Envio de documentos',
  TIPO_DOCUMENTO = 'Tipo de documento',
  ONBOARDING_DOCUMENTO = 'Onboarding documento',
  FOTO_DOCUMENTO = 'Foto documento',
  ANEXAR_DOCUMENTO = 'Anexar documento',
  REVISAR_FOTO = 'Revisar foto',
  ONBOARDING_FACIAL = 'Onboarding selfie',
  BIOMETRIA_CONCLUIDA = 'Selfie',
  REVISAO = 'Revisao',
  SUCESSO = 'Sucesso',
  ERRO_DIALOG = 'Erro dialog',
}
