import React, { Suspense, lazy } from 'react'

import { Route, Switch } from 'react-router-dom'

import { FallbackPage } from '@components/organisms'

import routes from './routes'

const LoadingPage = lazy(() => import('@pages/ContratacaoCompleta/LoadingPage'))
const CriacaoProposta = lazy(
  () => import('@pages/ContratacaoCompleta/DadosPessoais/CriacaoProposta'),
)
const StatusDocumentos = lazy(
  () => import('@components/pages/ContratacaoCompleta/EnvioDocumentos/StatusDocumentos'),
)
const ModoEnvio = lazy(
  () => import('@components/pages/ContratacaoCompleta/EnvioDocumentos/ModoEnvio'),
)
const ModoEnvioContracheque = lazy(
  () => import('@components/pages/ContratacaoCompleta/EnvioDocumentos/ModoEnvioContracheque'),
)
const FotoDocumento = lazy(
  () => import('@components/pages/ContratacaoCompleta/EnvioDocumentos/FotoDocumento'),
)
const FotoContraCheque = lazy(
  () => import('@components/pages/ContratacaoCompleta/EnvioDocumentos/FotoContraCheque'),
)
const BiometriaInstrucoes = lazy(
  () => import('@components/pages/ContratacaoCompleta/EnvioDocumentos/Biometria/Intrucoes'),
)
const Biometria = lazy(
  () => import('@components/pages/ContratacaoCompleta/EnvioDocumentos/Biometria/Video'),
)
const Revisao = lazy(() => import('@components/pages/ContratacaoCompleta/Revisao'))
const TermosContratacao = lazy(() => import('@components/pages/ContratacaoCompleta/Termos'))
const TermoVisualizacao = lazy(
  () => import('@components/pages/ContratacaoCompleta/Termos/VisualizadorTermo'),
)
const Endereco = lazy(() => import('@components/pages/ContratacaoCompleta/DadosPessoais/Endereco'))
const DadosBancarios = lazy(
  () => import('@components/pages/ContratacaoCompleta/DadosPessoais/DadosBancarios'),
)
const Sucesso = lazy(() => import('@components/pages/ContratacaoCompleta/Sucesso'))
const Antifraude = lazy(() => import('@components/pages/ContratacaoCompleta/Antifraude'))

const Routes = () => (
  <Switch>
    <Suspense fallback={<FallbackPage />}>
      <Route exact path={routes.ROOT} component={CriacaoProposta} />
      <Route exact path={routes.STATUS_DOCUMENTOS} component={StatusDocumentos} />
      <Route exact path={routes.MODO_ENVIO} component={ModoEnvio} />
      <Route exact path={routes.MODO_ENVIO_CONTRA_CHEQUE} component={ModoEnvioContracheque} />
      <Route exact path={routes.FOTO_DOCUMENTO} component={FotoDocumento} />
      <Route exact path={routes.FOTO_CONTRA_CHEQUE} component={FotoContraCheque} />
      <Route exact path={routes.BIOMETRIA_INSTRUCOES} component={BiometriaInstrucoes} />
      <Route exact path={routes.BIOMETRIA_VIDEO} component={Biometria} />
      <Route exact path={routes.REVISAO} component={Revisao} />
      <Route exact path={routes.TERMOS_CONTRATACAO} component={TermosContratacao} />
      <Route exact path={routes.TERMO_VISUALIZACAO} component={TermoVisualizacao} />
      <Route exact path={routes.ENDERECO} component={Endereco} />
      <Route exact path={routes.DADOS_BANCARIOS} component={DadosBancarios} />
      <Route exact path={routes.SUCESSO} component={Sucesso} />
      <Route exact path={routes.ANTIFRAUDE} component={Antifraude} />
      <Route exact path={routes.LOADING_PAGE} component={LoadingPage} />
    </Suspense>
  </Switch>
)

export default Routes
