import { useMemo } from 'react'

import { Convenios } from '@utils/enums'

const useFilteredConvenios = (idConvenio: string) => {
  const descricao = useMemo(() => {
    const idNumerico = Number(idConvenio)
    const convenioEncontrado = Convenios.find((convenio) => convenio.id === idNumerico)
    return convenioEncontrado ? convenioEncontrado.descricao : idConvenio
  }, [idConvenio])
  return descricao.toUpperCase()
}

export default useFilteredConvenios
