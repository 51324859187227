enum routes {
  ROOT = '/contratacao',
  STATUS_DOCUMENTOS = '/contratacao/status-documentos',
  MODO_ENVIO = '/contratacao/modo-envio',
  MODO_ENVIO_CONTRA_CHEQUE = '/contratacao/modo-envio-contracheque',
  BIOMETRIA_INSTRUCOES = '/contratacao/biometria-instrucoes',
  FOTO_DOCUMENTO = '/contratacao/foto-documento',
  FOTO_CONTRA_CHEQUE = '/contratacao/foto-contracheque',
  BIOMETRIA_VIDEO = '/contratacao/biometria',
  REVISAO = '/contratacao/revisao',
  TERMOS_CONTRATACAO = '/contratacao/termos-contratacao',
  TERMO_VISUALIZACAO = '/contratacao/termo-visualizacao',
  SUCESSO = '/contratacao/sucesso',
  ANTIFRAUDE = '/contratacao/nao-liberado',
  ENDERECO = '/contratacao/endereco',
  DADOS_BANCARIOS = '/contratacao/dados-bancarios',
  LOADING_PAGE = '/contratacao/carregamento-dados',
}

export default routes
