import { BridgeService } from '@services/bridge'

import { ITrackingProps } from './tracking.types'

/**
 * Envia os trackings app, web e consignado
 */
export const sendTrackings = (props: ITrackingProps) => {
  if (BridgeService.isBrowser()) {
    const dataLayer = {
      event: 'ga_event_body_cred',
      params: {
        section_name: props.screen,
        element_action: props.element_action,
        element_name: props.action_id,
        c_page: window.location.href,
        redirect_url: props.redirect_url || null,
      },
      params_input: props.params_input,
    }
    if (window.dataLayer) {
      window.dataLayer.push(dataLayer)
      // console.log(window.dataLayer.filter((event) => event.event === 'ga_event_body_cred'))
    }
  } else {
    BridgeService.requestAnalytics(props.name, {
      ref_figma: props.ref_figma,
      screen: String(props.screen),
      ref_type: String(props.ref_type),
      content_action: String(props.content_action),
      action_id: String(props.action_id),
      product: 'Novo',
      utm_campaign: props?.utm_campaign ?? '',
    })
  }
  if (props.callConsignadoTrack) {
    props.callConsignadoTrack(props.screen)
  }
}
