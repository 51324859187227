import { action, Action } from 'typesafe-actions'

import { StepsEnum } from '@utils/enums'
import { StepConsignadoTracking } from '@utils/tracking/tracking.types'

import { CriacaoPropostaPayload, CriacaoPropostaTypes, DocumentosEnvioType } from './types'

export const setNome = (payload: string): Action => action(CriacaoPropostaTypes.SET_NOME, payload)

export const setDataCriada = (payload: string): Action =>
  action(CriacaoPropostaTypes.SET_DATA_CRIADA, payload)

export const setNumeroProposta = (payload: string | number): Action =>
  action(CriacaoPropostaTypes.SET_NUMERO_PROPOSTA, payload)

export const setTipoDocumento = (payload: DocumentosEnvioType[]): Action =>
  action(CriacaoPropostaTypes.SET_TIPO_DOCUMENTO, payload)

export const setDocumentoId = (payload: string): Action =>
  action(CriacaoPropostaTypes.SET_DOCUMENTO_ID, payload)

export const setIdIteracao = (payload: string): Action =>
  action(CriacaoPropostaTypes.SET_ID_ITERACAO, payload)

export const criarProposta = (payload?: CriacaoPropostaPayload): Action =>
  action(CriacaoPropostaTypes.CRIAR_PROPOSTA, payload)

export const criarPropostaSuccess = (payload: string | number): Action =>
  action(CriacaoPropostaTypes.CRIAR_PROPOSTA_SUCCESS, payload)

export const criarPropostaError = (): Action => action(CriacaoPropostaTypes.CRIAR_PROPOSTA_ERROR)

export const trackingNewRelic = (step: StepsEnum | StepConsignadoTracking): Action =>
  action(CriacaoPropostaTypes.TRACK_NEW_RELIC, step)
