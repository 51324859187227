import { currencyToFloat } from '@interco/cp-react-ui-lib'
import { AssinaturaState } from '@store/assinatura'
import { PropostaState } from '@store/proposta'
import { SimulacaoState } from '@store/simulacao/types'
import { NavigationState } from '@store/ui/navigation/types'
import { UserState } from '@store/user/types'
import { removeMask } from '@utils/masks'

export const buildTrackingBody = (
  step: string,
  simulacao: SimulacaoState,
  proposta: PropostaState,
  user: UserState,
  assinatura: AssinaturaState,
  navigation: NavigationState,
) => ({
  step,
  numeroProposta: String(proposta.criacaoProposta.numeroProposta),
  estadoNaturalidade: proposta.nacionalidade.estadoSelecionado,
  cidadeNaturalidade: proposta.nacionalidade.cidadeSelecionada,
  estadoEndereco: proposta.endereco.estadoSelecionado,
  cidadeEndereco: proposta.endereco.cidadeSelecionada,
  tipoConta: proposta.dadosBancarios.tipoContaSelecionado,
  banco: proposta.dadosBancarios.bancoSelecionado,
  valorEmprestimo: currencyToFloat(assinatura.resumo.resumo.valorTotalASerPago),
  taxa: currencyToFloat(assinatura?.resumo.resumo.taxa),
  valorCET: assinatura?.resumo?.resumo.cet,
  valorLiberado: currencyToFloat(assinatura?.resumo.resumo.valorLiberado),
  creditoProtegido: assinatura?.resumo?.resumo.possuiSeguro,
  convenioId: simulacao?.idConvenio,
  antiFraude: proposta.dadosBancarios.contato,
  clientId: simulacao?.clientId,
  session: simulacao?.clientId,
  eventType: '',
  origem: navigation.origem,
  familia: navigation.familia,
  produto: 'NOVO',
  documento: simulacao?.cpf ? removeMask(simulacao?.cpf) : removeMask(user.cpfUser),
})
