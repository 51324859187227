import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Tag from '@utils/GoogleTag'
import { Text } from '@interco/inter-ui/components/Text'
import { clearError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { validaEnvHomeByBridge } from '@utils/functions'
import { BridgeService } from '@services/bridge'
import useFilteredConvenios from '@hooks/useFilteredConvenios'
import { StepConsignadoTracking } from '@utils/tracking/tracking.types'
import { ConveniosCodes } from '@utils/enums'
import { sendTrackings } from '@utils/tracking'

import { Header } from './styles'

const ErrorMessage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    showError = false,
    actionCallback,
    block,
    historyBack,
    functionCallback,
  } = useSelector((state: ApplicationState) => state.ui.error)
  const [open, setOpen] = useState(showError)
  const { idConvenio } = useSelector((state: ApplicationState) => state.simulacao)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const convenioDescricao = useFilteredConvenios(idConvenio)

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (open) {
      if (idConvenio === String(ConveniosCodes.INSS)) {
        sendTrackings({
          name: `NOVO_${convenioDescricao}_FLUXO`,
          ref_figma: '2',
          screen: StepConsignadoTracking.ERRO_DIALOG,
          ref_type: 'Feedback',
          content_action: 'Dado carregado',
          element_action: 'page_loaded',
          action_id: 'Estamos resolvendo um erro',
        })
      } else {
        Tag.contratacaoConsignadoTag({
          name: 'CONSIGNADO_FLUXO',
          ref_figma: '7',
          content_name: 'Estamos resolvendo um erro',
          ref_type: 'Feedback',
          tela: 'Erro dialog',
          content_action: 'Dado carregado',
          content_conv: convenioDescricao,
          product: convenioDescricao
            ? `CONSIGNADO_CONTRATACAO_${convenioDescricao.toUpperCase()}`
            : 'CONSIGNADO_CONTRATACAO',
        })
      }
      BridgeService.tac({
        name: 'mensagem_erro',
        isError: true,
        actions: [
          'criar_proposta',
          'buscar_documentos',
          'tirar_foto_biometria',
          'continuar_biometria_finalizada',
          'confirmar_contratacao',
        ],
        params: {
          covenant: convenioDescricao,
        },
      })
    }
  }, [convenioDescricao, idConvenio, isApp, open])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  if (open) {
    return (
      <BottomSheet
        onClose={
          !block
            ? () => {
                setOpen(false)
                setTimeout(() => {
                  dispatch(clearError())
                }, 600)

                if (historyBack) {
                  history.goBack()
                }
              }
            : () => null
        }
      >
        <Header>
          <Text variant="headline-h3" color="typography" semiBold>
            Estamos resolvendo um erro
          </Text>
        </Header>
        <Text variant="body-3">
          Mas fique tranquilo, em breve você poderá continuar o seu processo.
        </Text>

        <Button
          fullWidth
          style={{ marginTop: '16px' }}
          onClick={() => {
            if (idConvenio === String(ConveniosCodes.INSS)) {
              sendTrackings({
                name: `NOVO_${convenioDescricao}_FLUXO`,
                ref_figma: '2',
                screen: StepConsignadoTracking.ERRO_DIALOG,
                ref_type: 'Botao',
                content_action: 'Toque',
                element_action: 'click button',
                action_id: 'Tentar novamente',
              })
            } else {
              Tag.contratacaoConsignadoTag({
                name: 'CONSIGNADO_FLUXO',
                ref_figma: '8',
                content_name: 'Tentar novamente',
                ref_type: 'Botao',
                tela: 'Erro dialog',
                content_action: 'Toque',
                content_conv: convenioDescricao,
                product: convenioDescricao
                  ? `CONSIGNADO_CONTRATACAO_${convenioDescricao.toUpperCase()}`
                  : 'CONSIGNADO_CONTRATACAO',
              })
            }
            setOpen(false)
            setTimeout(() => {
              dispatch(clearError())
              if (actionCallback instanceof Function) {
                dispatch(actionCallback())
              } else if (functionCallback instanceof Function) {
                functionCallback()
              }
            }, 600)
          }}
        >
          Tentar novamente
        </Button>
        <Button
          fullWidth
          variant="secondary"
          style={{ marginTop: '16px' }}
          onClick={() => {
            if (idConvenio === String(ConveniosCodes.INSS)) {
              sendTrackings({
                name: `NOVO_${convenioDescricao}_FLUXO`,
                ref_figma: '2',
                screen: StepConsignadoTracking.ERRO_DIALOG,
                ref_type: 'Botao',
                content_action: 'Toque',
                element_action: 'click button',
                action_id: 'Voltar para home',
                redirect_url: validaEnvHomeByBridge(isApp),
              })
            } else {
              Tag.contratacaoConsignadoTag({
                name: 'CONSIGNADO_FLUXO',
                ref_figma: '8',
                content_name: 'Voltar para home',
                ref_type: 'Botao',
                tela: 'Erro dialog',
                content_action: 'Toque',
                content_conv: convenioDescricao,
                product: convenioDescricao
                  ? `CONSIGNADO_CONTRATACAO_${convenioDescricao.toUpperCase()}`
                  : 'CONSIGNADO_CONTRATACAO',
              })
            }
            setOpen(false)
            window.location.href = validaEnvHomeByBridge(isApp) || ''
          }}
        >
          Voltar para home
        </Button>
      </BottomSheet>
    )
  }
  return <></>
}

export default ErrorMessage
